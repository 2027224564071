<template>
  <div
    ref="menuButton"
    class="options-button-container"
  >
    <div
      class="button"
      @click="menuButtonPressed"
    >
      ⋮
    </div>
    <div v-if="open" class="options-wrapper">
      <div
        v-for="option in options"
        :key="option.id"
        class="option"
        :class="{red : option.red}"
        @click="optionSelected(option)"
      >
        <InlineSvg
          :src="option.icon"
          class="option-icon"
        />
        <div class="option-text">
          {{ option.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import { onMounted, onUnmounted } from '@vue/runtime-core'
import InlineSvg from 'vue-inline-svg'

export default {
  name: 'OptionsButton',
  components: {
    InlineSvg
  },
  props: {
    options: {
      required: true,
      type: Object,
      default: () => {
        return {}
      },
    },
    rowData: {
      required: false,
      type: Object,
      default: () => {
        return {}
      }
    },
    closeOnSelection: {
      required: false,
      type: Boolean,
      default: true
    },
    startOpen: {
      type: Boolean,
      required: false,
      default: false
    },
  },
  setup(props) {
    const menuButton = ref(null)
    const open = ref(props.startOpen)

    function menuButtonPressed() {
      open.value = !open.value
    }

    function checkIfClicked(e) {
      if (e.target === menuButton.value) {
        open.value = !open.value
      } else if (!menuButton.value.contains(e.target)) {
        open.value = false
      }
    }

    onMounted(() => {
      document.addEventListener('click', checkIfClicked)
    })

    onUnmounted(() => {
      document.removeEventListener('click', checkIfClicked)
    })


    function optionSelected(option) {
      if (props.closeOnSelection) {
        open.value = false
      }
      option.action(props.rowData)
    }

    return {
      menuButtonPressed,
      optionSelected,

      open,

      menuButton
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";

.options-button-container {
  position: relative;
  cursor: pointer;


  .button {
    background-color: #373B52;
    color: white;
    padding: rem(3) rem(12);
    font-weight: 1200;
    font-size: rem(20);
    border-radius: rem(5);
  }

  .options-wrapper {
    position: absolute;
    background-color: #fff;
    width: fit-content;
    margin-top: 5px;
    border-radius: rem(8);
    box-shadow: rem(2) rem(2) rem(2) rem(2) rgba(0, 0, 0, 0.1);
    z-index: 1;
    right: 0;

    .option {
      min-width: rem(200);
      cursor: pointer;
      display: flex;
      align-items: center;
      padding: 16px;
      text-decoration: none;
      color: #5D7591;

      border-top: 1px solid #F4F6FB;

      &:first-child {
        border-top: none;
        border-radius: rem(8) rem(8) 0 0;
      }

      &:last-child {
        border-radius: 0 0 rem(8) rem(8);
      }

      &:hover {
        background-color: #F4F6FB;
      }
    }

    .option-icon {
      width: rem(20);
      margin-right: 10px;
      :deep path {
        fill: var(--blue_light);
      }
    }

    .red {
      color: var(--red_error);
      :deep path {
        fill: var(--red_error);
      }
    }

    .option-text {
      white-space: nowrap;
    }
  }
}


</style>
